import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControl from '@material-ui/core/FormControl'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Slide from '@material-ui/core/Slide'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { TransitionProps } from '@material-ui/core/transitions'
import { ISeat } from 'models/Reservation.model'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  findAvailableSeats,
  IFindAvailableSeatsParams
} from 'store/thunk/findAvailableSeats'
import { getReservationDetail } from 'store/thunk/getReservationDetail'
import { updateReservation } from 'store/thunk/updateReservation'
import { updateReservationStatus } from 'store/thunk/updateStatus'
import {
  closeFormModal,
  selectFormModalState
} from '../../../store/reducers/ConfirmSeatModalReducer'
import { selectReservations } from '../../../store/reducers/ReservationsReducer'
import Loader from '../Loader'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      width: '100%'
    },
    dialogContent: {
      overflow: 'hidden'
    },
    confirmButton: {
      backgroundColor: theme.palette.secondary.main,
      color: '#fff',
      boxShadow: 'none',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
        filter: 'brightness(0.85)',
        boxShadow: 'none'
      },
      '&:disabled': {
        backgroundColor: '#efeef5',
        color: '#717171'
      }
    },
    cancelButton: {
      backgroundColor: '#efeef5',
      color: '#717171',
      textTransform: 'capitalize',
      boxShadow: 'none',
      '&:hover': {
        backgroundColor: '#efeef5',
        filter: 'brightness(0.85)',
        boxShadow: 'none'
      }
    }
  })
)

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ConfirmSeatDialog: React.VFC = () => {
  const classes = useStyles()
  const { detailreservation, loading } = useSelector(selectReservations)
  const [selectedSeat, setSelectedSeat] = useState<ISeat>()
  const { open, reservationId } = useSelector(selectFormModalState)
  const { seats } = useSelector(selectReservations)
  const [availableSeats, setAvailableSeats] = useState<ISeat[]>([])
  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false)
  const dispatch = useDispatch()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSeat(JSON.parse(event.target.value as string) as ISeat)
  }

  const handleClose = () => {
    dispatch(closeFormModal())
  }

  const handleConfirm = async () => {
    setIsFormDisabled(true)

    detailreservation.reservationStatus.status !== 'ACCEPTED' &&
      (await dispatch(
        updateReservationStatus({
          id: detailreservation.id,
          action: 'ACCEPTED'
        })
      ))

    await dispatch(
      updateReservation({
        ...detailreservation,
        seat: selectedSeat
      })
    )

    setIsFormDisabled(false)
    dispatch(closeFormModal())
  }

  useEffect(() => {
    if (reservationId && open) {
      dispatch(getReservationDetail({ reservationId }))
    }
  }, [reservationId, dispatch, open])

  useEffect(() => {
    if (detailreservation) {
      const params = {
        dateStart: moment(detailreservation.dateStart)
          .startOf('day')
          .toISOString(),
        numberOfOccurrence: 1,
        recurrencePatternType: 'DAILY',
        recurrenceRangeType: 'NUMBERED',
        recurrenceTimeZone: 'Europe/Rome',
        seriesName: 'daily_numbered',
        recurringReservation: true,
        timeReservation: 539
      } as IFindAvailableSeatsParams
      dispatch(findAvailableSeats(params))
    }
  }, [detailreservation, dispatch])

  useEffect(() => {
    if (detailreservation && seats) {
      const { seat } = detailreservation
      if (seats.some(s => s.id === seat.id)) {
        /**
         * this is the case when the reservation status is pending
         * the available seats api will return also the selected seat, so there's no need to append into the availableSeats state
         */
        setAvailableSeats(seats)
      } else {
        /**
         * give to the user the available seat options & the selected one
         * if the reservation status !== pending, the available seats api will return only the available seats (doesn't include the selected one)
         * so we need to append the selected seat into the availableSeats state
         */
        setAvailableSeats([seat, ...seats])
      }
    }
  }, [seats, detailreservation])

  useEffect(() => {
    if (detailreservation && availableSeats.length > 0) {
      const found = availableSeats.find(s => s.id === detailreservation.seat.id)
      setSelectedSeat(found)
    }
  }, [availableSeats, detailreservation])
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle>
          Conferma il posto prima di approvare la prenotazione
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          {detailreservation && (
            <DialogContentText>
              Prenotazione per{' '}
              {moment(detailreservation.dateStart).format('dddd DD MMMM')}{' '}
              {detailreservation.seat.room.description}:
              {detailreservation.seat.room.name}
            </DialogContentText>
          )}

          {selectedSeat && detailreservation && (
            <form className={classes.container}>
              <FormControl className={classes.formControl}>
                <InputLabel id="confirm-seat-dialog-select-inputlabel">
                  Scegli il piano e posti
                </InputLabel>
                <Select
                  labelId="confirm-seat-dialog-select-label"
                  id="confirm-seat-dialog-select"
                  value={JSON.stringify(selectedSeat)}
                  label={`${selectedSeat?.room?.description} ${selectedSeat?.description}`}
                  onChange={handleChange}
                  input={<Input />}
                >
                  {availableSeats.map((seat: ISeat) => (
                    <MenuItem key={seat.id} value={JSON.stringify(seat)}>
                      {`${seat?.room?.name} ${seat.description}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </form>
          )}
          {!selectedSeat && !detailreservation && loading && <Loader />}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            className={classes.cancelButton}
          >
            Annulla
          </Button>
          <Button
            disabled={isFormDisabled}
            onClick={handleConfirm}
            color="primary"
            className={classes.confirmButton}
          >
            Conferma
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ConfirmSeatDialog
