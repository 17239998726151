import { CssBaseline } from '@material-ui/core/'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles'
import App from 'app'
import { LoadingBar } from 'app/components'
import MyDialog from 'app/components/MyDialog'
import 'moment/locale/it'
import React from 'react'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import * as ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import reportWebVitals from 'reportWebVitals'
import 'sanitize.css/sanitize.css'
import { ThemeProvider } from 'styled-components'
import { GlobalStyle } from 'styles/global-styles'
import { theme } from 'styles/theme'
import ConfirmSeatDialog from './app/components/ConfirmSeatDialog'
import RedirectDialog from './app/components/RedirectDialog'
import Toast from './app/components/Toast'
import NetworkStatus from './app/components/Booking/NetworkStatus'
// Initialize languages
import './locales/i18n'
import { store } from './store/configureStore'
import { KeycloakProvider } from 'keycloak-react-web'
import keycloak from 'keycloak'
import RefreshTokenWrapper from 'app/axios/RefreshTokenWrapper'

const MOUNT_NODE = document.getElementById('root') as HTMLElement

ReactDOM.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <HelmetProvider>
      <>
        <StylesProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <GlobalStyle />
              <NetworkStatus />
              <LoadingBar color={theme.palette.secondary.main} />
              <MyDialog />
              <ConfirmSeatDialog />
              <RedirectDialog />
              <Toast />
              <KeycloakProvider client={keycloak} initOptions={{ onLoad: "login-required" }}>
                <RefreshTokenWrapper>
                  <BrowserRouter>
                    <App />
                  </BrowserRouter>
                </RefreshTokenWrapper>  
              </KeycloakProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </>
    </HelmetProvider>
  </Provider>,
  MOUNT_NODE
)

// Hot reloadable translation json files
//TODO ignore error detected by pipeline
// @ts-ignore
if (module.hot) {
  // @ts-ignore
  module.hot.accept(['./locales/i18n'], () => {
    // No need to render the App again because i18next works with the hooks
  })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
