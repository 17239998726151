import * as React from 'react'
import { useEffect } from 'react'
import Routes from './routes'
import { takFM } from '../utils/tak-fm'

const r = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}

const App = () => {
  useEffect(() => {
    const root = document.getElementById('root')
    Object.assign(root?.style, r)

    // start TAK floating menu
    takFM();
  }, [])

  return <Routes />
}

export default App
