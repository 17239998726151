import axios, { AxiosError, AxiosResponse } from 'axios'

const axiosInstance = axios.create()

const authData = ['userJwt', 'auth']

export const resetSignIn = (redirect = true) => {
  authData.forEach((name: string) => localStorage.setItem(name, ''))
  if (redirect) {
    window.location.href = '/'
  }
}

let token = localStorage.getItem('userJwt')

const requestInterceptor = config => {
  token = localStorage.getItem('userJwt')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

const responseInterceptor = (response: AxiosResponse) => response

export const statuses = [401, 403]

const errorInterceptor = (error: AxiosError) => {

  const status = error.response?.status || 0

  if (statuses.includes(status)) {
    resetSignIn(!!error.config.headers['X-No-Redirect'])
    return Promise.reject(error)
  }

  return Promise.reject(error)
}

axiosInstance.interceptors.request.use(requestInterceptor, errorInterceptor)
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor)

export default axiosInstance
