// all the values with $ will be overwritten from foreign configuration
const CONFIG = {
  BROWSER: '$BROWSER',
  FAST_REFRESH: '$FAST_REFRESH',
  EXTEND_ESLINT: '$EXTEND_ESLINT',
  REACT_APP_BASE_URL: '$REACT_APP_BASE_URL',
  REACT_APP_PAGE_SIZE: '$REACT_APP_PAGE_SIZE',
  SKIP_PREFLIGHT_CHECK: '$SKIP_PREFLIGHT_CHECK',
  REACT_APP_KEYCLOAK_URL: '$REACT_APP_KEYCLOAK_URL',
  REACT_APP_KEYCLOAK_REALM: '$REACT_APP_KEYCLOAK_REALM',
  REACT_APP_KEYCLOAK_CLIENTID: '$REACT_APP_KEYCLOAK_CLIENTID',
  REACT_APP_MAXIMUM_AVAILABLE_SEATS: '$REACT_APP_MAXIMUM_AVAILABLE_SEATS'
}

export const value = (key): string => {
  let value = CONFIG[key]

  // this value wasn't overwritten => get it from process.env
  if (value && value === '$' + key) {
    value = process.env[key]
  }

  return value
}
