import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import axiosInstance from '../../app/axios'
import {
  IReservationContent,
  IReservationsError,
  ISeat
} from '../../models/Reservation.model'
import { isAxiosError } from '../../utils/errors'
import { RES_CONTROLLER } from './../../constants/URLS'
export interface IUpdateParams {
  id: number
  action: string
  seat?: ISeat
}

export const updateReservationStatus = createAsyncThunk<
  IReservationContent,
  IUpdateParams | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'reservation/updateReservationStatus',
  async ({ id, action }: IUpdateParams, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.put(
        `${RES_CONTROLLER.updateStatus}/${id}/${action}`
      )

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        const error = err as AxiosError<IReservationsError, any>
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
