import { value } from 'constants/conf'

const baseUrl = value('REACT_APP_BASE_URL')

export const AUTH_CONTROLLER = {
  signIn: `${baseUrl}/signin`,
  signUp: `${baseUrl}/signup`
}

export const RES_CONTROLLER = {
  myReservations: `${baseUrl}/reservations/my-reservations`,
  toApproveReservations: `${baseUrl}/reservations/to-approve`,
  updateStatus: `${baseUrl}/reservations/updateStatus`,
  updateReservation: `${baseUrl}/reservations/update-reservation`,
  countSeats: `${baseUrl}/reservations/count-available-seats-on-dates`,
  createGeneric: `${baseUrl}/reservations/generic`,
  createSpecific: `${baseUrl}/reservations/specific`,
  byRole: `${baseUrl}/reservations/find-reservation-by-role`,
  findSeats: `${baseUrl}/reservations/find-available-seats`,
  recursiveReservation: `${baseUrl}/reservations/recursive-reservation`,
  groupReservation: `${baseUrl}/reservations/specific-reservation-group`,
  recursiveGroupReservation: `${baseUrl}/reservations/recursive-reservation-group`,
  reservationDetail: `${baseUrl}/reservations/find-reservation`
}

export const USER_CONTROLLER = {
  getUsers: `${baseUrl}/users/all`,
  createUser: `${baseUrl}/users/addUser`,
  deleteUser: `${baseUrl}/users/delete`,
  updateUser: `${baseUrl}/users/update`,
  getUserDetails: `${baseUrl}/users/get-user-details`
}

export const GROUP_CONTROLLER = {
  getGroups: `${baseUrl}/groups/findAllEnabled`,
  createGroup: `${baseUrl}/groups/create-group`,
  updateGroup: `${baseUrl}/groups/update-group`,
  deleteGroup: `${baseUrl}/groups/delete-group`
}

export const AUTHORITY_CONTROLLER = {
  getAuthorities: `${baseUrl}/authorities`
}

export const SEAT_CONTROLLER = {
  countSeat: `${baseUrl}/seat/total-number`
}
