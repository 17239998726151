import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from '../../utils/errors'
import axiosInstance, { statuses } from '../../app/axios'
import { SEAT_CONTROLLER } from '../../constants/URLS'
import { IReservationsError } from '../../models/Reservation.model'
import { showToast } from 'store/reducers/ToastReducer'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'

export const countTotalSeats = createAsyncThunk<
  any,
  undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'ReservationsDay/countTotalSeats',

  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get<number>(
        SEAT_CONTROLLER.countSeat
      )
      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: countTotalSeats,
              params: undefined
            })
          )
        }
        const error = err as AxiosError<IReservationsError,any>
        return rejectWithValue(error.response)
      }
    }
  }
)
