import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { isAxiosError } from 'utils/errors'
import { RES_CONTROLLER } from '../../constants/URLS'
import { IReservationsError } from '../../models/Reservation.model'
import axiosInstance, { statuses } from 'app/axios'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'
import { showToast } from 'store/reducers/ToastReducer'

export interface IDateParams {
  fromDate: string
  toDate: string
}

export const countSeats = createAsyncThunk<
  any,
  IDateParams,
  {
    rejectValue: AxiosResponse<IReservationsError>
  }
>(
  'reservation/countSeats',
  async (params: IDateParams, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(RES_CONTROLLER.countSeats, {
        params
      })

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: countSeats,
              params
            })
          )
        }
        const error = err as AxiosError<IReservationsError,any>
        return rejectWithValue(error.response)
      }
    }
  }
)
