import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError, AxiosResponse } from 'axios'
import { RES_CONTROLLER } from '../../constants/URLS'
import { AppDispatch, RootState } from '../../store/configureStore'
import { isAxiosError } from '../../utils/errors'
import axiosInstance, { statuses } from '../../app/axios'
import {
  IMyReservationParams,
  IMyReservationsResponse,
  IReservationContent,
  IReservationContentWithDate,
  IReservationsError
} from '../../models/Reservation.model'
import { getUrlParams } from '../../utils/urlrelated'
import { hide, show } from '../reducers/LoaderReducer'
import { showToast } from 'store/reducers/ToastReducer'
import { changeLastAction, setError } from 'store/reducers/ErrorActionReducer'

export const myReservationsCalendar = createAsyncThunk<
  IMyReservationsResponse<IReservationContentWithDate>,
  IMyReservationParams | undefined,
  {
    rejectValue: AxiosResponse<IReservationsError>
    state: RootState
    dispatch: AppDispatch
  }
>(
  'reservation/myReservationsCalendar',
  async (
    params: IMyReservationParams,
    { dispatch, rejectWithValue, getState }
  ) => {
    try {
      let path = getUrlParams(params)
      dispatch(show())

      const { data } = await axiosInstance.get<
        IMyReservationsResponse<IReservationContent>
      >(RES_CONTROLLER.myReservations + path)

      dispatch(hide())

      if (params.page > 0) {
        if (data.first) {
          return {
            ...data
          }
        }
        return {
          ...data,
          content: [
            ...getState().reservations.myreservations.content,
            ...data.content
          ]
        }
      }

      return data
    } catch (err) {
      if (isAxiosError(err)) {
        if (statuses.includes(err.response.status)) {
          dispatch(
            showToast({
              message: 'Errore, Riprovando...',
              type: 'error',
              time: 3000
            })
          )
          dispatch(setError(true))
          dispatch(
            changeLastAction({
              f: myReservationsCalendar,
              params
            })
          )
        }
        const error = err as AxiosError<IReservationsError,any>
        return rejectWithValue(error.response)
      } else {
        // generic & not related to network error
        console.error(err)
      }
    }
  }
)
