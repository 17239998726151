export const takFM = (): void => {
  const observer = new MutationObserver(mutations => {
    const bodyEl = document.querySelector('body');
    if (document.contains(bodyEl)) {

      // css style
      const style = document.createElement('style');
      style.textContent = `
        .tak-fm {
          position: fixed;
          top: 30%;
          right: 0;
          padding: 2px 7px 8px 8px;
          border-top: 2px SOLID #41b6ae;
          border-left: 2px SOLID #41b6ae;
          border-bottom: 2px SOLID #41b6ae;
          border-radius: 12px 0 0 12px;
          background-color: #e3f2fd;
          z-index: 9000;
          -webkit-transition: all 1.2s ease-in;
          -moz-transition: all 1.2s ease-in;
          -o-transition: all 1.2s ease-in;
          transition: all 1.2s ease-in;
        }
        
        .tak-fm:hover {
          width: 120px;
        }
        
        .tak-fm-content {
          display: flex;
          flex-direction: column;
        }
        
        .tak-fm a {
          display: flex;
          justify-content: end;
          align-items: center;
          padding-top: 6px;
          font-family: sans-serif !important;
          font-size: 16px !important;
          text-decoration: none;
          color: #1565c0 !important;
        }
        
        .tak-fm a:hover {
          font-weight: bold;
        }
        
        .tak-fm a:hover img {
          height: 34px;
        }
        
        .tak-fm img {
          height: 30px;
          padding: 2px;
        }
        
        .tak-fm .tak-title {
          display: none;
          padding-right: 6px;
        }
        
        .tak-fm:hover .tak-title {
          display: block;
        }`;
      bodyEl.append(style);

      // html
      var wrapperDiv = document.createElement('div');
      wrapperDiv.setAttribute('class', 'tak-fm');
      wrapperDiv.innerHTML = `
        <div class="tak-fm-content">
          <a href="https://wiki.tak.dev.koor.it" title="Wiki">
            <span class="tak-title">Wiki</span>
            <img src="https://wiki.tak.dev.koor.it/_assets/favicons/android-chrome-192x192.png" />
          </a>
          <a href="https://reservations.tak.dev.koor.it/reservations" title="Prenota">
            <span class="tak-title">Prenota</span>
            <img src="https://cdn4.iconfinder.com/data/icons/business-office-blue-series/64/a-23-512.png" />
          </a>
          <a href="https://totem.techgap.it" title="Totem">
            <span class="tak-title">Totem</span>
            <img src="https://totem.techgap.it/totem/app/img/totem.png" />
          </a>
        </div>`;
      bodyEl.appendChild(wrapperDiv);

      observer.disconnect();
    }
  });

  observer.observe(document, {
    attributes: false,
    childList: true,
    characterData: false,
    subtree: true
  });
}
